@use '../variables';

mat-tab-group.settings {
  .mat-mdc-tab-header {
    --mat-tab-header-label-text-size: 20px;
  }
  .mat-mdc-tab-labels{
    margin-bottom: 30px;
  }
};
