@use 'styles/variables';

.bot-results {
  table {
    border-spacing: 0;
    border-collapse: collapse;

  }

  td, th {
    padding: 5px 7px;
    border: 1px solid var(--dark-primary-text);
  }
}
