//@use '../variables';

mtx-grid.mtx-grid {
  .mat-header-cell {
    white-space: nowrap;
  }
}

.theme-dark {
  .mat-mdc-paginator {
    background-color: transparent;
  }
  .mat-mdc-table {
    background: none;
  }
}
