
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 350px !important;
  width: 350px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.32);
  background: var(--black) !important;

  .menu-title {
    padding: 8px 0;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .mat-mdc-menu-content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .mat-mdc-menu-item {
    min-height: auto;
    padding: 6px 8px;
    border-radius: 8px;
    background: var(--black);
    border: 1px solid var(--secondary);
    transition: all 0.2s ease;
    margin: 0;

    &:hover {
      border-color: var(--accent);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    }
  }

  .mat-mdc-menu-item-text {
    font-size: 13px !important;

    .mdc-list-item__primary-text {
      white-space: normal;
      line-height: 1.4;
      font-weight: 500;
    }
  }
}
