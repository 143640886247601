@use '../variables';

.gingr-input {
  //background: var(--primary);
}
.theme-dark {
  --mat-option-selected-state-layer-color: #{var(--secondary)};
  --mat-select-panel-background-color: #{var(--primary)};
  --mat-option-selected-state-label-text-color: #{var(--light-primary-text)};
  --mat-option-focus-state-layer-color: #{var(--secondary)};

  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #{var(--tertiary)};
  }

  --mat-autocomplete-background-color: #{var(--primary)};

  .gingr-input {
    .mat-mdc-text-field-wrapper {
    }
    .mat-mdc-form-field-infix {
      padding: 24px 16px 8px 16px;
    }
    &:not(.gingr-input--dark) {
      .mdc-text-field--filled {
        &:not(.mdc-text-field--disabled) {
          background-color: var(--secondary);
        }
      }
    }
    &.gingr-input--no-hint {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
    &.gingr-input--dark {
      .mdc-text-field--filled {
        &:not(.mdc-text-field--disabled) {
          background-color: var(--primary);
        }
      }
    }
  }
}

.gingr-input-wrapper {
  display: flex;
  flex-direction: column;

  input {
    font-family: variables.$font-family;
  }

  &--with-form {
    width: 100%;
    flex-direction: row;
    form {
      display: flex;
      width: 100%;
    }
  }

  .gingr-input--light {
    color: var(--primary);
    border: solid 1px var(--primary);
    background: var(--white);
    font-family: variables.$font-family;
    font-size: 16px;
    padding: 8px 16px;
  }

  mat-error {
    margin-top: 4px;
    font-size: 12px;
  }

  .gingr-input--primary {
    background: var(--primary);
    color: var(--light-primary-text);
    padding: 8px 16px;
    border-radius: 8px;
    width: 100%;
    border: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: var(--primary);
    color: var(--light-primary-text);
    padding: 0;

    textarea {
      padding: 8px;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: var(--secondary);
    padding: 8px;
    border-radius: 8px;

    .mat-mdc-select {
      font-size: 14px;
    }
  }
  div.mat-mdc-select-panel {
    background: var(--secondary);
  }
  .mat-mdc-option {
    font-size: 14px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--light-primary-text);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--light-primary-text);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--light-primary-text);
  }
}

.custom-label {
  color: var(--white);
  font-size: 12px;
  opacity: 0.8;
  margin-bottom: 8px;
  margin-left: 10px;
}

.secondary-button {
  border: 1px solid !important;
}

.asterisk {
  color: red;
}

.mdc-switch {
  width: 48px !important;

  .mdc-switch__track {
    height: 20px !important;

    &::after, &::before {
      border-radius: 16px !important;
    }
  }
}

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track::after, .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track::before {
  background: transparent !important;
  border: 1px solid var(--light-primary-text) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: var(--accent) !important;
}

.theme-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .theme-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--accent) !important;
}
//chip list and selector
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none !important;
}

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  padding-bottom: 20px;
}
.theme-dark .mat-mdc-form-field-focus-overlay {
   background-color: rgba(255, 255, 255, 0.4);
}


/**
 * Editable inputs
 */
input.editable-field {
  background: none;
  border: none;
  font-family: variables.$font-family;
  font-size: 16px;
  color: white;
  font-weight: 600;
  padding: 5px;
  &:hover{
    background-color: rgba(42, 46, 65, 0.40);
  }
  &:focus {
    outline: none;
    background-color: #2A2E41;
    width: 100%;
  }
}

input.is-editing {

}

.hide-sub {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.mat-mdc-form-field {
  width: 100%;
  
  .mat-mdc-form-field-flex {
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    line-height: 1.5;
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .mdc-text-field--filled .mdc-floating-label {
    font-size: 14px;
    margin-top: 2px;
    top: unset;
  }

  .mat-mdc-floating-label {
    margin-top: -4px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
    
    &::before,
    &::after {
      display: none;
    }
  }

  textarea.mat-mdc-input-element {
    margin: 0;
    padding: 0;
  }

  .mat-mdc-select-value-text,
  .mat-mdc-select-placeholder {
    font-size: 14px;
  }

  // TODO: Leave it till end of testing
  &.mat-mdc-form-field-type-mat-input,
  &.mat-mdc-form-field-type-mat-select {
    .mat-mdc-form-field-infix {
      // min-height: 48px;
    }
  }
}

// Paginator fix
.mat-mdc-paginator {
  .mat-mdc-form-field-infix {
    padding: 0;
  }
}