//palette colors:

$accent: #FF9D00;
$accent-gradient: linear-gradient(255deg, #FD581D 0%, #FFA127 100%);
$primaryLight: #F5F6FD;
$primaryDark: #13172c;
$secondaryLight: #E0E8F7;
$secondaryDark: #1d233d;
$font-family: 'Open Sans', sans-serif;
$drawer-width: 290px;
$content-left-padding: 48px;
$base-content-padding: 18px;
$modal-padding: 48px;
$drawer-base-padding: 8px;
$light-background: #F5F7FC;
$light-message-bg: #E0E7F7;

$white-abstract-bg: #fff;

// CSS variables for themes
.theme-light {
  --primary: #{$primaryLight};
  --accent: #{$accent};
  --accent-gradient: #{$accent-gradient};
  --secondary: #{$secondaryLight};
  --secondary-lighter: #EEF0FA;
  --secondary-light: #494e67;
  --tertiary: #494e67;
  --dark-primary-text: #474954;
  --light-primary-text: #848a96;
  --icon-bg: #494e67;
  --success: #03dc41;
  --success-dark: rgba(3, 220, 65, 0.77);
  --error: #dc0303;
  --white: #000;
  --black: #fff;
  --message-assistant: #E0E8F7;
  --message-user: white;
  --light-alwyas: #{$primaryLight};
  --light-background: #{$light-background};
  --light-message-bg: #{$light-message-bg};
  --light-abstract-bg: #{$white-abstract-bg};
  --prompt-bg: white;
  --prompt-bg-hover: white;
  --prompt-active: white;
  --prompt-placeholder: rgba(25, 25, 25, 0.7);
  --prompt-border-inactive: #a6a6a6;

  --playlist-container-bg: #EEF0FA;
  --playlist-bg: #{$primaryLight};
  --playlist-bg-hover: #BBC1E2;
  --playlist-active: #BBC1E2;
  --table-bg: var(--black);
}

.theme-dark {
  --primary: #{$primaryDark};
  --accent: #{$accent};
  --accent-gradient: #{$accent-gradient};
  --secondary: #{$secondaryDark};
  --secondary-lighter: #2a3354;
  --secondary-light: #e0e7f7;
  --tertiary: #494e67;
  --dark-primary-text: #818493;
  --light-primary-text: #e0e7f7;
  --icon-bg: #{$light-message-bg};
  --success: #03dc41;
  --success-dark: rgba(3, 220, 65, 0.77);
  --error: #dc0303;
  --white: #fff;
  --black: #000;
  --message-assistant: #1d233d;
  --message-user: rgb(73, 78, 103);
  --light-alwyas: #{$primaryLight};
  --light-background: #12172C;
  --light-message-bg: #{$light-message-bg};
  --light-abstract-bg: #{$primaryDark};

  --prompt-bg: inherit;
  --prompt-bg-hover: #1F2235;
  --prompt-active: #2F3243;
  --prompt-placeholder: rgba(255, 255, 255, 0.7);
  --prompt-border-inactive: rgba(255, 255, 255, 0.5);


  --playlist-container-bg: #{$secondaryDark};;
  --playlist-bg: #2a3354;
  --playlist-bg-hover: #BBC1E2;
  --playlist-active: #BBC1E2;
  --table-bg: var(--light-background);
}


.brand-takeda {
  --icon-bg: #E1242A; // Cool vibrant red for icons
  --secondary: #FFF0F0; // Very light pink/red for background
  --secondary-lighter: #FFE6E6; // Even lighter shade for hover states
  --secondary-light: #FF8A8A; // Light red for secondary elements
  --tertiary: #FF4757; // Another shade of red for tertiary elements
  --primary: #fff;
  --light-background: #fff;
  --light-message-bg: #FFF0F0;
  --accent: #E1242A;
  --accent-gradient: linear-gradient(255deg, #E1242A 0%, #FF4757 100%);
  --message-user: var(--secondary);
}

.theme-dark.brand-takeda {
  --icon-bg: #{$light-message-bg};
  --secondary: #{$secondaryDark};
  --secondary-lighter: #2a3354;
  --secondary-light: #e0e7f7;
  --tertiary: #494e67;
  --primary: #{$primaryDark};
  --light-background: #12172C;
  --light-message-bg: #{$light-message-bg};
  --accent: #{$accent};
  --accent-gradient: linear-gradient(255deg, #E1242A 0%, #FFA127 100%);
  --message-user: rgb(73, 78, 103);
}



