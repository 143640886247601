/** Override Material styles */

@use '../../app/theme/style/variables';
@use './styles/variables' as main_variables;

.mat-mdc-card {
  margin-bottom: variables.$gutter;
}

.mat-mdc-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.form-field-full {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.form-field-half {
  .mat-mdc-form-field {
    width: 50%;
  }
}

.mat-mdc-menu-panel  {
  background-color: var(--secondary) !important;
  color: var(--dark-primary-text) !important;
}
