@use '@angular/material' as mat;
@use 'app-theme';
@use 'sass:map';
@use 'variables';


$gingr-light-primary-palette: (
  50: variables.$primaryDark,
  100: variables.$primaryDark,
  200: variables.$primaryDark,
  300: variables.$primaryDark,
  400: variables.$primaryDark,
  500: variables.$primaryDark,
  600: variables.$primaryDark,
  700: variables.$primaryDark,
  800: variables.$primaryDark,
  900: variables.$primaryDark,
  A100: variables.$primaryDark,
  A200: variables.$primaryDark,
  A400: variables.$primaryDark,
  A700: variables.$primaryDark,
  contrast: (
    50: variables.$primaryLight,
    100: variables.$primaryLight,
    200: variables.$primaryLight,
    300: variables.$primaryLight,
    400: variables.$primaryLight,
    500: variables.$primaryLight,
    600: variables.$primaryLight,
    700: variables.$primaryLight,
    800: variables.$primaryLight,
    900: variables.$primaryLight,
    A100: variables.$primaryLight,
    A200: variables.$primaryLight,
    A400: variables.$primaryLight,
    A700: variables.$primaryLight,
  ),
);

$gingr-light-accent-palette: (
  50: #fff4e5,
  100: #ffe3be,
  200: #ffd093,
  300: #ffbd68,
  400: #ffaf47,
  500: var(--accent),
  600: #ff9923,
  700: #ff8f1d,
  800: #ff8517,
  900: #ff740e,
  A100: #ffffff,
  A200: #fffaf6,
  A400: #ffdac3,
  A700: #ffcbaa,
  contrast: (
    50: variables.$primaryDark,
    100: variables.$primaryDark,
    200: variables.$primaryDark,
    300: variables.$primaryDark,
    400: variables.$primaryDark,
    500: variables.$primaryDark,
    600: variables.$primaryDark,
    700: variables.$primaryDark,
    800: variables.$primaryDark,
    900: variables.$primaryDark,
    A100: variables.$primaryDark,
    A200: variables.$primaryDark,
    A400: variables.$primaryDark,
    A700: variables.$primaryDark,
  ),
);

$gingr-dark-primary-palette: (
  50: #e3e3e6,
  100: #b8b9c0,
  200: #898b96,
  300: #5a5d6b,
  400: #363a4c,
  500: #13172c,
  600: #111427,
  700: #0e1121,
  800: #0b0d1b,
  900: #060710,
  A100: #536cff,
  A200: #2041ff,
  A400: #0022ec,
  A700: #001ed3,
  contrast: (
    50: var(--primary-text),
    100: var(--primary-text),
    200: var(--primary-text),
    300: var(--primary-text),
    400: var(--primary-text),
    500: var(--primary-text),
    600: var(--primary-text),
    700: var(--primary-text),
    800: var(--primary-text),
    900: var(--primary-text),
    A100: var(--primary-text),
    A200: var(--primary-text),
    A400: var(--primary-text),
    A700: var(--primary-text),
  ),
);

$gingr-dark-accent-palette: (
  50: variables.$accent,
  100: variables.$accent,
  200: variables.$accent,
  300: variables.$accent,
  400: variables.$accent,
  500: variables.$accent,
  600: variables.$accent,
  700: variables.$accent,
  800: variables.$accent,
  900: variables.$accent,
  A100: variables.$accent,
  A200: variables.$accent,
  A400: variables.$accent,
  A700: variables.$accent,
  contrast: (
    50: variables.$primaryDark,
    100: variables.$primaryDark,
    200: variables.$primaryDark,
    300: variables.$primaryDark,
    400: variables.$primaryDark,
    500: variables.$primaryDark,
    600: variables.$primaryDark,
    700: variables.$primaryDark,
    800: variables.$primaryDark,
    900: variables.$primaryDark,
    A100: variables.$primaryDark,
    A200: variables.$primaryDark,
    A400: variables.$primaryDark,
    A700: variables.$primaryDark,
  ),
);

$typography-config: mat.define-legacy-typography-config(
  $font-family: variables.$font-family,
    /*<h1>*/
  $headline: mat.define-typography-level(40px, 40px, normal),
    /*<h2>*/
  $title: mat.define-typography-level(24px, 24px, normal),
    /*<h3>*/
  $subheading-2: mat.define-typography-level(
    $font-weight: bold,
    $font-size: 20px,
    $line-height: 1,
    $letter-spacing: normal,
  ),
    /*<h4>*/
  $subheading-1: mat.define-typography-level(
    $font-weight: 400,
    $font-size: 18px,
    $line-height: 1,
    $letter-spacing: normal,
  ),
  $display-4: mat.define-typography-level(18px, 18px, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(14px, 14px, 400, $letter-spacing: -0.02em),
    /*body*/
  $body-1: mat.define-typography-level(16px, 32px, 600),
  $caption: mat.define-typography-level(14px, 30px, 500),
  $button: mat.define-typography-level(14px, 30px, 500),
  $display-2: mat.define-typography-level(12px, 12px, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(14px, 14px, 400),
  $body-2: mat.define-typography-level(12px, 25px, 600),
  $input: mat.define-typography-level(14px, 1),
);

$dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: mat.define-palette($gingr-dark-primary-palette),
        accent: mat.define-palette($gingr-dark-accent-palette),
      ),
      typography: $typography-config,
      density: null,
    )
);

$light-theme: mat.define-light-theme(
    (
      color: (
        primary: mat.define-palette($gingr-light-primary-palette),
        accent: mat.define-palette($gingr-light-accent-palette),
      ),
      typography: $typography-config,
      density: null,
    )
);
.mat-secondary,
.mat-secondary:not(:disabled) {
  background-color: var(--primary);
  color: var(--light-primary-text);
}

.mat-tertiary,
.mat-tertiary:not(:disabled) {
  background-color: var(--tertiary);
  color: var(--light-primary-text);
}

.mat-drawer-container {
  position: initial !important;
}

.gingr-content {
  .mat-mdc-icon-button {
    --mdc-icon-button-state-layer-size: 24px;
    --mdc-icon-button-icon-size: 24px;
    padding: 0;
  }

  width: 100%;
  height: 100%;

  .menu-icon__tiny {
    width: 16px;
    height: 16px;
  }

  &__drawer-content {
    display: block;
    padding: variables.$drawer-base-padding variables.$drawer-base-padding variables.$drawer-base-padding variables.$content-left-padding;
    position: relative;
    z-index: 3;
    overflow: visible;

    &__group-item {
      & > span {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &__item {
      display: flex;
      gap: 8px;
      align-items: center;
      min-width: 0;

      &.item-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      &.selected {
        background-color: var(--primary);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &__sufix {
        font-size: 12px;
        line-height: 2;
        opacity: 0.5;
      }
    }

    .drawer-toggle {
      position: absolute;
      box-shadow: none;
      bottom: 130px;
      left: -4px;
      border-radius: 0 15px 15px 0;
      height: 30px;
      padding: 0;
      width: 30px;
      min-width: 0;
    }
  }

  &__drawer {
    width: variables.$drawer-width;
    background: var(--secondary);
    border-left: solid 1px var(--secondary);
    border-right: solid 1px var(--secondary);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px;
      font-size: 14px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &.gingr-content__drawer-content--grouped {
        li {
          //width: variables.$drawer-width - 30px;
          flex-direction: column;
          align-items: center;
        }
      }

      li {
        display: flex;
        gap: 8px;
        padding: 8px 8px 8px 8px;
        margin-left: 0;
        font-size: 14px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        
        &:hover {
          background-color: var(--light-message-bg);
        }
      }
    }

    label {
      color: var(--primary-text);
    }
  }
}

//.theme-light{

$backgroundColor: var(--primary);
$color: map.get($light-theme, 'color');
$colorBackground: map.get($color, 'background');
$colorBackground: map.set($colorBackground, 'background', $backgroundColor);
$color: map.set($color, 'background', $colorBackground);
$light-theme: map.set($light-theme, 'color', $color);
@include mat.all-component-themes($light-theme);
@include mat.all-component-typographies($typography-config);
//}

.theme-dark {
  $backgroundColor: variables.$primaryDark ;
  $color: map.get($dark-theme, 'color');
  $colorBackground: map.get($color, 'background');
  $colorBackground: map.set($colorBackground, 'background', $backgroundColor);
  $color: map.set($color, 'background', $colorBackground);
  $dark-theme: map.set($dark-theme, 'color', $color);
  @include mat.all-component-colors($dark-theme);
  @include mat.button-color($light-theme);

  mat-dialog-container.mat-mdc-dialog-container {
    --mdc-dialog-container-color: #{var(--primary)};
  }

  .mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #{var(--secondary-light)};
    --mdc-slider-focus-handle-color: #{var(--secondary-light)};
    --mdc-slider-hover-handle-color: #{var(--secondary-light)};
    --mdc-slider-active-track-color: #{var(--accent)};
    --mdc-slider-inactive-track-color: #{var(--secondary-light)};
  }

  .mat-success,
  .mat-success:not(:disabled) {
    &:not(.mat-mdc-progress-bar) {
      background-color: var(--success);
      color: var(--black);
    }

    &.mat-mdc-progress-bar {
      --mdc-linear-progress-active-indicator-color: #{var(--success)};
      --mdc-linear-progress-track-color: rgba(12, 236, 79, 0.25);
    }
  }

 button.mat-primary,
 button.mat-primary:not(:disabled) {
    &:not(.mat-mdc-progress-bar) {
      background-color: var(--tertiary);
      color: var(--white);
    }
  }

  // Specific primary disabled style if needed
  button.mat-primary:disabled {
    color: #474747;
    background-color: #888888;
  }

  .mat-error,
  .mat-error:not(:disabled) {
    &:not(.mat-mdc-progress-bar) {
      background-color: var(--error);
      color: var(--black);
    }

    &.mat-mdc-progress-bar {
      --mdc-linear-progress-active-indicator-color: #{var(--error)};
      --mdc-linear-progress-track-color: rgba(236, 12, 12, 0.25);
    }
  }

  .mat-mdc-menu-item.selected {
    background: var(--mat-menu-item-hover-state-layer-color);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--secondary);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--tertiary);
    cursor: default;
    position: relative;
    z-index: 1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--tertiary);
  }
}

.theme-dark, .theme-light {
  .gingr-content {
    &__drawer {
      background: var(--secondary);
      border-left: solid 1px var(--secondary);
      border-right: solid 1px var(--secondary);

      ul.gingr-content__drawer-content--nested {
        overflow: hidden;
        transition: transform 0.25s ease;
        transform-origin: top;
        transform: scaleY(1);
        position: relative;

        &--hidden {
          transition: all 0.15s ease;
          opacity: 0;
          position: absolute;
          transform: scaleY(0);
        }

        li {
          &:hover {
            cursor: pointer;
            font-weight: 600;
          }
        }
      }

      label {
        color: var(--dark-primary-text);
      }
    }
  }
}

//overwrites
:root {
  --mdc-typography-subtitle1-font-size: 14px;
  --mdc-typography-body1-font-size: 14px;
  --mdc-list-list-item-label-text-size: 12px;
  .mdc-list-item__primary-text {
    font-size: 12px;
  }
}

// Global disabled button styles
button:disabled,
.mat-mdc-button-base:disabled {
  opacity: 0.5;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--accent) !important;
}

mark.marked-text {
  background: rgba(117, 76, 0, 0.75);
  animation: fadeIn 0.5s ease;
  color: white;
}

@keyframes fadeIn {
  0% {
    background: transparent;
  }
  100% {
    background: rgba(117, 76, 0, 0.75)
  }
}

.cdk-overlay-pane {
  // TODO: find other way to set min-width for slr filters because it's applying to all dialogs
  min-width: 280px;
}

.mdc-button.mat-mdc-raised-button.mat-accent {
  background: linear-gradient(255deg, #FD581D 0%, #FFA127 100%);
  color: #fff;
}

.brand-prod,
.brand-takeda {
  .mdc-button.mat-mdc-raised-button.mat-accent {
    background: var(--accent-gradient);
  }
}
:root {
  // Webkit (Chrome, Safari, Edge) scrollbar
  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {

    background-color: #a8bbbf;
  
  }
}