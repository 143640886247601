@use 'scrollbar';
@use 'badge';
@use 'icon';

.scrollbar-thin {
  @include scrollbar.thin();
}

.scrollbar-none {
  @include scrollbar.none();
}

.badge {
  @include badge.badge();
}

@each $size in [18, 24, 36, 48] {
  .icon-#{$size} {
    @include icon.icon($size);
  }
}

@media (width <= 720px) {
  .hide-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }
}

.fs-14 {
  font-size: 14px;
}

.max-width-10 {
  max-width: 10%;
}

.max-width-15 {
  max-width: 15%;
}

.max-width-20 {
  max-width: 20%;
}

.max-width-50 {
  max-width: 50%;
}
